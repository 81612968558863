<template>
  <div id="login">
    <pc v-if="screenWidth > 1366" />
    <mobile v-else />
  </div>
</template>

<script>
import elementResizeDetector from 'element-resize-detector'
import Pc from '@/views/login/PC'
import Mobile from '@/views/login/Mobile'
export default {
  name: 'LoginIndex',
  components: {
    Pc,
    Mobile
  },
  data() {
    return {
      screenWidth: '',
      screenHeight: ''
    }
  },

  mounted() {
    const that = this
    const erd = elementResizeDetector()
    erd.listenTo(document.getElementById('login'), (element) => {
      that.screenWidth = element.offsetWidth
      that.screenHeight = element.offsetHeight
    })
  }
}
</script>
