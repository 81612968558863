<template>
  <div class="login-layout-wrapper">
    <div
      class="custom-login-bg"
      :style="{background: '#335a3f url(' + loginBgUrl + ') no-repeat 50%',
               backgroundSize: 'cover', backgroundAttachment: 'fixed'}"
    >
      <div class="custom-login">
        <div class="custom-login-right">
          <div class="custom-login-form">
            <div class="login-border">
              <div class="login-logo">
                <img :src="logoUrl" class="logo" alt="logo">
              </div>
              <h2 class="custom-login-title">
                {{ appName }}
              </h2>
              <div class="login-content">
                <a-form
                  id="components-form-demo-normal-login"
                  :form="form"
                  @submit="handleSubmit"
                >
                  <a-form-item class="custom-margin-bottom-10">
                    <a-input
                      @change="handleEmpNo"
                      v-decorator="['emp_no', {
                        normalize: this.$lodash.trim,
                        rules: [
                          { required: true, message: '请输入工号' }
                        ]
                      }]"
                      placeholder="工号"
                    />
                  </a-form-item>

                  <a-form-item class="custom-margin-bottom-10">
                    <a-input
                      v-decorator="['password', {
                        rules: [
                          { required: true, message: '请输入登录密码' }
                        ]
                      }]"
                      type="password"
                      placeholder="密码"
                    />
                  </a-form-item>

                  <a-form-item class="custom-margin-bottom-10">
                    <a-select
                      show-search
                      :filter-option="filterOption"
                      option-filter-prop="children"
                      :loading="loadingFuneralHomeOptions"
                      @focus="fetchFuneralHomeOptions"
                      placeholder="所属殡仪馆"
                      v-decorator="['funeral_home_id', {
                        rules: [{ required: true, message: '请选择所属殡仪馆' }]
                      }]"
                    >
                      <a-select-option
                        v-for="home in funeralHomeOptions"
                        :key="home.id"
                        :value="home.id"
                      >
                        {{ home.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>

                  <a-form-item>
                    <a-row :gutter="8">
                      <a-col :span="18">
                        <a-input
                          placeholder="验证码"
                          v-decorator="['captcha', {
                            normalize: this.$lodash.trim,
                            rules: [{ required: true, message: '请输入验证码' }]
                          }]"
                        />
                      </a-col>
                      <a-col :span="6">
                        <a-tooltip>
                          <template slot="title">
                            点击刷新验证码
                          </template>

                          <a-spin :spinning="loadingCaptchaImg">
                            <img
                              v-if="captchaId.length > 0"
                              class="captcha-img custom-cursor-pointer"
                              @click="reloadCaptcha"
                              @load="captchaImgLoaded"
                              :src="captchaImgUrl"
                              alt=""
                            >
                          </a-spin>
                        </a-tooltip>
                      </a-col>
                    </a-row>
                  </a-form-item>
                  <a-form-item>
                    <a-button
                      type="primary"
                      html-type="submit"
                      :loading="submitting"
                      class="login-form-button"
                    >
                      立即登录
                    </a-button>
                  </a-form-item>
                </a-form>
                <div v-html="tips" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a-row
        class="footer"
        type="flex"
        justify="space-around"
        align="middle"
      >
        <span>{{ copyright }}</span>
      </a-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { newCaptcha, reloadCaptcha } from '@/api/captcha'
import { findFuneralHomeOptions } from '@/api/funeral_home'
export default {
  name: 'Login',
  data() {
    // 节流
    this.calmDown = this.$lodash.throttle(this.calmDown, 4000)
    return {
      redirect: undefined,
      captchaId: '',
      captchaImgUrl: '',
      loadingCaptchaImg: false,
      loadingFuneralHomeOptions: false,
      funeralHomeOptions: [],
      submitting: false,
      busy: false, // 为 true 时提示操作频繁
      form: this.$form.createForm(this, { name: 'login' })
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      }
    }
  },
  created() {
    this.newCaptchaId()
  },
  computed: {
    appName() {
      return window.customConfig.name
    },

    tips() {
      return window.customConfig.loginTips
    },

    loginBgUrl() {
      return window.customConfig.loginBgUrl
    },

    logoUrl() {
      return window.customConfig.logoUrl
    },

    copyright() {
      return window.customConfig.copyright
    }
  },
  methods: {
    ...mapActions(['Login']),

    // 获取新的验证码id
    newCaptchaId() {
      this.loadingCaptchaImg = true
      newCaptcha().then(res => {
        if (res.code === 0) {
          this.captchaId = res.data.id
          this.fetchCaptchaImgUrl()
        }
      })
    },

    calmDown() {
      this.busy = false
    },

    tooBusyMessage() {
      this.$message.error('操作太频繁，请稍后再试')
    },

    // 检测是否频繁操作
    checkBusy() {
      this.calmDown()

      if (this.busy) {
        this.tooBusyMessage()
        return true
      } else {
        this.busy = true
        return false
      }
    },

    // 根据验证码id刷新验证码
    reloadCaptcha() {
      if (this.loadingCaptchaImg || this.submitting) {
        this.tooBusyMessage()
        return
      }

      if (this.checkBusy()) {
        return
      }

      this.loadingCaptchaImg = true
      reloadCaptcha(this.captchaId).then(res => {
        if (res.code === 0) {
          this.fetchCaptchaImgUrl()
        }
      })
    },

    // 验证码图片加载完成
    captchaImgLoaded() {
      this.loadingCaptchaImg = false
    },

    // 加载验证码图片url
    fetchCaptchaImgUrl() {
      this.captchaImgUrl = `${window.customConfig.apiBaseUrl}/captcha/${this.captchaId}?v=${Math.random()}`
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },

    handleEmpNo(e) {
      this.form.setFieldsValue({ funeral_home_id: undefined })
      this.funeralHomeOptions = []
    },

    fetchFuneralHomeOptions() {
      const empNo = this.form.getFieldValue('emp_no')
      if (!empNo || this.funeralHomeOptions.length > 0) {
        return
      }
      this.loadingFuneralHomeOptions = true
      findFuneralHomeOptions({ emp_no: empNo }).then((res) => {
        if (res.code === 0) {
          this.funeralHomeOptions = res.data
        }
      })
      this.loadingFuneralHomeOptions = false
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.loadingCaptchaImg) {
            this.tooBusyMessage()
            return
          }
          if (this.checkBusy()) {
            return
          }
          this.submitting = true
          this.Login(Object.assign({ captcha_id: this.captchaId }, values)).then(() => {
            this.submitting = false
            this.$router.push({ path: this.redirect || '/' })
          }).catch(error => {
            console.log(error)
            // 重新生成验证码id
            this.newCaptchaId()
            this.form.setFieldsValue({ captcha: '' })
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.login-layout-wrapper {
  height: 100%;

  .custom-login-bg {
    position: fixed;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex-wrap: wrap;

    .custom-login {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .custom-login-right {
        display: flex;
        padding: 40px;

        .custom-login-form {
          flex-basis: 400px;
          width: 90%;

          .login-border {
            margin: 0 auto;
            padding: 30px;
            border: 1px solid #5f7c6a;
            border-radius: 8px;
            background-color: #2a4c39;
            opacity: 0.9;

            .login-logo {
              margin-bottom: 20px;
              text-align: center;

              .logo {
                width: 100px;
                height: 100px;
              }
            }

            .custom-login-title {
              font-weight: 300;
              text-align: center;
              color: @white;
            }

            .login-content {
              padding: 30px 40px;

              .login-form-button {
                width: 100%;
                border-color: @white;
                color: @black;
                background-color: rgba(255 228 173);
              }
            }
          }
        }
      }

      .captcha-img {
        float: right;
        margin-top: 5px;
        width: 100%;
        height: 30px;
        background: @yellow;
      }
    }

    .footer {
      display: flex;
      width: 100%;

      span {
        color: rgba(255 228 173);
      }
    }
  }
}

</style>
