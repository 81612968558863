import request from '@/utils/request'

// 殡仪馆选项
export function findFuneralHomeOptions(params) {
  return request({
    url: `/funeral_home/options`,
    method: 'get',
    params: params
  })
}
